import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledCertificatesSection = styled.section`
  max-width: 700px;
  margin: 0 auto;
  padding: 80px 0;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .certificates-list {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
    margin-top: 50px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Change to one column for mobile */
      justify-items: center; /* Center items horizontally */
    }
  }

  .certificate {
    position: relative;
    cursor: default;
    transition: var(--transition);

    &:hover,
    &:focus-within {
      .certificate-inner {
        transform: translateY(-7px);
      }
    }

    a {
      position: relative;
      z-index: 1;
    }

    .certificate-inner {
      ${({ theme }) => theme.mixins.boxShadow};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      height: 100%;
      padding: 1.75rem;
      border-radius: var(--border-radius);
      background-color: var(--light-navy);
      transition: var(--transition);
      overflow: hidden;
    }

    .certificate-title {
      margin: 0 0 10px;
      color: var(--lightest-slate);
      font-size: var(--fz-lg);

      a {
        position: static;

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }

    .certificate-image {
      width: 150px;
      height: 150px;
      max-height: 150px;
      border-radius: var(--border-radius);
      ${({ theme }) => theme.mixins.boxShadow};
      transition: var(--transition);

      &:hover,
      &:focus {
        transform: translateY(-7px);
      }
    }
  }

  .more-button {
    ${({ theme }) => theme.mixins.button};
    display: block;
    margin: 80px auto 0;
  }
`;


const Certificates = () => {
  const [showMore, setShowMore] = useState(false);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const data = useStaticQuery(graphql`
    query {
      Coursera1: file(relativePath: { eq: "Coursera1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 150, height: 150)
        }
      }
      certfc1: file(relativePath: { eq: "certfc1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 150, height: 150)
        }
      }
      certfc2: file(relativePath: { eq: "certfc2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 150, height: 150)
        }
      }
      certfc3: file(relativePath: { eq: "certfc3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 150, height: 150)
        }
      }
      certfc4: file(relativePath: { eq: "certfc4.jpg" }) { 
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 150, height: 150)
        }
      }
    }
  `);

  const certificates = [
    {
      name: ' ',
      url: 'https://www.learnenough.com/certificates/momosh',
      imageSrc: 'https://www.learnenough.com/certificates/momosh/command-line-tutorial.svg',
      alt: 'Certificate of Completion for Learn Enough Command Line',
      isExternal: true,
    },
    {
      name: ' ',
      url: 'https://www.learnenough.com/certificates/momosh',
      imageSrc: 'https://www.learnenough.com/certificates/momosh/text-editor-tutorial.svg',
      alt: 'Certificate of Completion for Learn Enough Text Editor',
      isExternal: true,
    },
    {
      name: ' ',
      url: 'https://www.learnenough.com/certificates/momosh',
      imageSrc: 'https://www.learnenough.com/certificates/momosh/git-tutorial.svg',
      alt: 'Certificate of Completion for Learn Enough Git',
      isExternal: true,
    },

    {
      name: ' ',
      url: 'https://www.learnenough.com/certificates/momosh',
      imageSrc: 'https://www.learnenough.com/certificates/momosh/html-tutorial.svg',
      alt: 'Certificate of Completion for Learn Enough HTML',
      isExternal: true,
    },
    {
      name: ' ',
      url: 'https://www.learnenough.com/certificates/momosh',
      imageSrc: 'https://www.learnenough.com/certificates/momosh/python-tutorial.svg',
      alt: 'Certificate of Completion for Learn Enough Python',
      isExternal: true,
    },
    // ... (other external certificates)
    {
      name: ' ',
      url: '#',
      imageSrc: getImage(data.Coursera1.childImageSharp.gatsbyImageData),
      alt: 'Certificate 1',
      isExternal: false,
    },
    {
      name: ' ',
      url: '#',
      imageSrc: getImage(data.certfc1.childImageSharp.gatsbyImageData),
      alt: 'Certificate 2',
      isExternal: false,
    },
    {
      name: ' ',
      url: '#',
      imageSrc: getImage(data.certfc2.childImageSharp.gatsbyImageData),
      alt: 'Certificate 3',
      isExternal: false,
    },
    {
      name: ' ',
      url: '#',
      imageSrc: getImage(data.certfc3.childImageSharp.gatsbyImageData),
      alt: 'Certificate 4',
      isExternal: false,
    },
    {
      name: ' ',
      url: '#',
      imageSrc: getImage(data.certfc4.childImageSharp.gatsbyImageData),
      alt: 'Certificate 5',
      isExternal: false,
    },
  ];

  const renderCertificates = () => {
    const visibleCertificates = showMore ? certificates : certificates.slice(0, 4);

    return (
      <TransitionGroup component={null}>
        {visibleCertificates.map((certificate, index) => (
          <CSSTransition
            key={index}
            classNames="fadeup"
            timeout={index >= 4 ? (index - 4) * 300 : 300}
            exit={false}
          >
            <li className="certificate">
              <a href={certificate.url} target="_blank" rel="noopener noreferrer">
                <div className="certificate-inner">
                  <h3 className="certificate-title">
                    <a href={certificate.url} target="_blank" rel="noopener noreferrer">
                      {certificate.name}
                    </a>
                  </h3>
                  {certificate.isExternal ? (
                    <img
                      className="certificate-image"
                      src={certificate.imageSrc}
                      alt={certificate.alt}
                    />
                  ) : (
                    <GatsbyImage
                      className="certificate-image"
                      image={certificate.imageSrc}
                      alt={certificate.alt}
                    />
                  )}
                </div>
              </a>
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  };

  return (
    <StyledCertificatesSection id="certificates" ref={revealContainer}>
      <h2 className="numbered-heading">Certificates</h2>

      <ul className="certificates-list">{renderCertificates()}</ul>

      {certificates.length > 4 && (
        <button className="more-button" onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Show Less' : 'Show More'}
        </button>
      )}
    </StyledCertificatesSection>
  );
};

export default Certificates;